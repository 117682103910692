.default-list-group {
    list-style: none;
    margin: 0;
    padding: 0;
    border: 1px solid rgba(60,188,195,1);
    border-color: var(--black-l4);
    border-radius: 0.5rem;
    background-color: rgba(255,255,255,1);
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 500;
    color: rgba(29,29,44,1);
}
.default-list-group li {
    padding: 0.5rem 1rem;
    border-bottom: 1px solid rgba(60,188,195,1);
    border-color: var(--black-l4);
    width: 100%;
    box-sizing: border-box;
    display: flex;
}
.default-list-group li:last-child {
    border-width: 0;
}
.default-list-group li span:first-child {
    padding: 0 2rem 0 0;
}