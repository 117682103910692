.tw-button {
  color: rgba(255, 255, 255, 1);
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25rem;
  text-decoration: none;
  cursor: pointer;
  padding: 0.625rem 1.25rem;
  background-color: rgba(60, 188, 195, 1);
  border-radius: 0.5rem;
  border-width: 0;
  display: inline-flex;
}

.tw-button:hover {
  background-color: var(--blue-d1);
}

.tw-button:focus {
  box-shadow: 0 0 0 4px var(--blue-l2);
}

.btn-close {
  opacity: 0.5;
  cursor: pointer;
  border: none;
  border-radius: 0.25rem;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e");
  background-size: 50% 50%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-color: white;
  color: rgba(0, 0, 0, 1);
  width: 1rem;
  height: 1rem;
  box-sizing: content-box;
  padding: 0.25rem;
}

.btn-close:focus,
.btn-close:hover {
  opacity: 1;
  box-shadow: 0px 0px 0px 0.25rem rgba(60, 188, 195, 0.25);
  outline: 0 solid rgba(0, 0, 0, 0);
}

.btn-row {
  display: flex;
  gap: 0.75rem;
  flex-wrap: wrap;
}

.btn-row.lg {
  gap: 1rem;
}

.btn-row.right {
  justify-content: flex-end;
}

.btn-box {
  position: relative;
  display: inline-block;
  margin: 0;
  padding: 0;
}

.m-btn {
  cursor: pointer;
  border: none;
  border-radius: 0.25rem;
  outline: none;
  display: inline-flex;
  align-items: center;
  position: relative;
  box-sizing: border-box;
  padding: 0.25rem 0.5rem;
  font-size: var(--fs-s);
  line-height: var(--lh-s);
  text-decoration: none;
  white-space: nowrap;
  background-color: var(--white);
  box-shadow: var(--shadow-form-element);
  transition: all 0.3s;
  z-index: 2;
}

.m-btn:hover {
  background-color: var(--blue-l3);
  box-shadow: var(--shadow-form-element-hover);
}

.m-btn:disabled {
  color: var(--black-l1);
  background-color: var(--black-l6);
  border: 1px solid var(--black-l3);
  box-shadow: none;
  cursor: default;
}

.m-btn.disabled:hover {
  pointer-events: none;
}

.m-btn .svg-box {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
}

.m-btn .svg-box svg {
  width: 1rem;
  height: auto;
  fill: var(--black-l1);
}

.m-btn .svg-box.right {
  right: 0;
  padding-right: 0.375rem;
}

.m-btn.small {
  padding: 0.375rem 0.75rem;
}

.m-btn-box {
  display: inline-flex;
  gap: 0.5rem;
}

/* Additional classes for levels up to level81 */
.m-btn.active {
  background-color: rgba(60, 188, 195, 0.25);
  color: rgba(60, 188, 195, 1);
}

.edit-item {
  color: rgba(60, 188, 195, 1);
}

.m-btn.active-other {
  color: var(--blue);
}

.m-btn.lin-grad {
  background-image: linear-gradient(45deg, rgba(213, 250, 252, 1), rgba(255, 255, 255, 1) 50%, rgba(213, 250, 252, 1) 100%);
}

.m-btn.lin-grad:hover {
  background-image: linear-gradient(45deg, rgba(193, 251, 254, 1), rgba(255, 255, 255, 1) 50%, rgba(193, 251, 254, 1) 100%);
}

.m-btn.lin-grad.disabled:hover,
.m-btn.lin-grad-del.disabled:hover {
  pointer-events: none;
}

.m-btn.lin-grad.active {
  background-image: linear-gradient(45deg, rgba(193, 251, 254, 1), rgba(213, 250, 252, 1) 50%, rgba(193, 251, 254, 1) 100%);
}

.m-btn.lin-grad-del {
  background-image: linear-gradient(45deg, rgba(255, 236, 207, 1), rgba(255, 255, 255, 1) 50%, rgba(255, 236, 207, 1) 100%);
}

.m-btn.lin-grad-del:hover {
  background-image: linear-gradient(45deg, rgba(253, 221, 173, 1), rgba(255, 255, 255, 1) 50%, rgba(253, 221, 173, 1) 100%);
}

.m-btn.lin-grad-del.active {
  background-image: linear-gradient(45deg, rgba(253, 221, 173, 1), rgba(255, 255, 255, 1) 50%, rgba(253, 221, 173, 1) 100%);
}

.m-btn.level1,
.m-btn-box.level1 {
  margin-left: 0;
}

.m-btn.level2,
.m-btn-box.level2 {
  margin-left: 0.5rem;
}

.m-btn.level3,
.m-btn-box.level3 {
  margin-left: 1rem;
}

.m-btn.level4,
.m-btn-box.level4 {
  margin-left: 1.5rem;
}

.m-btn.level5,
.m-btn-box.level5 {
  margin-left: 2rem;
}

.m-btn.level6,
.m-btn-box.level6 {
  margin-left: 2.5rem;
}

.m-btn.level7,
.m-btn-box.level7 {
  margin-left: 3rem;
}

.m-btn.level8,
.m-btn-box.level8 {
  margin-left: 3.5rem;
}

.m-btn.level9,
.m-btn-box.level9 {
  margin-left: 4rem;
}

.m-btn.level10,
.m-btn-box.level10 {
  margin-left: 4.5rem;
}

.m-btn.level11,
.m-btn-box.level11 {
  margin-left: 5rem;
}

.m-btn.level12,
.m-btn-box.level12 {
  margin-left: 5.5rem;
}

.m-btn.level13,
.m-btn-box.level13 {
  margin-left: 6rem;
}

.m-btn.level14,
.m-btn-box.level14 {
  margin-left: 6.5rem;
}

.m-btn.level15,
.m-btn-box.level15 {
  margin-left: 7rem;
}

.m-btn.level16,
.m-btn-box.level16 {
  margin-left: 7.5rem;
}

.m-btn.level17,
.m-btn-box.level17 {
  margin-left: 8rem;
}

.m-btn.level18,
.m-btn-box.level18 {
  margin-left: 8.5rem;
}

.m-btn.level19,
.m-btn-box.level19 {
  margin-left: 9rem;
}

.m-btn.level20,
.m-btn-box.level20 {
  margin-left: 9.5rem;
}

.m-btn.level21,
.m-btn-box.level21 {
  margin-left: 10rem;
}

.m-btn.level22,
.m-btn-box.level22 {
  margin-left: 10.5rem;
}

.m-btn.level23,
.m-btn-box.level23 {
  margin-left: 11rem;
}

.m-btn.level24,
.m-btn-box.level24 {
  margin-left: 11.5rem;
}

.m-btn.level25,
.m-btn-box.level25 {
  margin-left: 12rem;
}

.m-btn.level26,
.m-btn-box.level26 {
  margin-left: 12.5rem;
}

.m-btn.level27,
.m-btn-box.level27 {
  margin-left: 13rem;
}

.m-btn.level28,
.m-btn-box.level28 {
  margin-left: 13.5rem;
}

.m-btn.level29,
.m-btn-box.level29 {
  margin-left: 14rem;
}

.m-btn.level30,
.m-btn-box.level30 {
  margin-left: 14.5rem;
}

.m-btn.level31,
.m-btn-box.level31 {
  margin-left: 15rem;
}

.m-btn.level32,
.m-btn-box.level32 {
  margin-left: 15.5rem;
}

.m-btn.level33,
.m-btn-box.level33 {
  margin-left: 16rem;
}

.m-btn.level34,
.m-btn-box.level34 {
  margin-left: 16.5rem;
}

.m-btn.level35,
.m-btn-box.level35 {
  margin-left: 17rem;
}

.m-btn.level36,
.m-btn-box.level36 {
  margin-left: 17.5rem;
}

.m-btn.level37,
.m-btn-box.level37 {
  margin-left: 18rem;
}

.m-btn.level38,
.m-btn-box.level38 {
  margin-left: 18.5rem;
}

.m-btn.level39,
.m-btn-box.level39 {
  margin-left: 19rem;
}

.m-btn.level40,
.m-btn-box.level40 {
  margin-left: 19.5rem;
}

.m-btn.level41,
.m-btn-box.level41 {
  margin-left: 20rem;
}

.m-btn.level42,
.m-btn-box.level42 {
  margin-left: 20.5rem;
}

.m-btn.level43,
.m-btn-box.level43 {
  margin-left: 21rem;
}

.m-btn.level44,
.m-btn-box.level44 {
  margin-left: 21.5rem;
}

.m-btn.level45,
.m-btn-box.level45 {
  margin-left: 22rem;
}

.m-btn.level46,
.m-btn-box.level46 {
  margin-left: 22.5rem;
}

.m-btn.level47,
.m-btn-box.level47 {
  margin-left: 23rem;
}

.m-btn.level48,
.m-btn-box.level48 {
  margin-left: 23.5rem;
}

.m-btn.level49,
.m-btn-box.level49 {
  margin-left: 24rem;
}

.m-btn.level50,
.m-btn-box.level50 {
  margin-left: 24.5rem;
}

