.top-nav-wrapper {
  padding: 1.4rem 2rem;
  background-color: var(--white);
  display: flex;
}

.top-nav-wrapper .nav-logo {
  display: inline-flex;
  align-items: center;
}

.top-nav-wrapper .nav-logo svg {
  display: inline-block;
  width: 100%;
}

.top-nav-wrapper .cssexy-logo,
.top-nav-wrapper .main,
.top-nav-wrapper .signed-in,
.top-nav-wrapper .signed-out {
  width: 33.33333333%;
  flex: 0 0 auto;
  display: flex;
}

.top-nav-wrapper .main {
  justify-content: center;
}

.top-nav-wrapper .signed-in,
.top-nav-wrapper .signed-out {
  justify-content: flex-end;
}

.mid-nav-wrapper-box {
  background-color: var(--white);
  padding: 0.75rem 0;
}

.mid-nav-wrapper .main .full,
.mid-nav-wrapper .main .no-css {
  display: flex;
  justify-content: space-between;
}

.mid-nav-wrapper .main .full > div,
.mid-nav-wrapper .main .no-css > div {
  width: auto;
  flex: 0 0 auto;
  display: flex;
}

.mid-nav-wrapper .main .full .main-subpage,
.mid-nav-wrapper .main .full .upload,
.mid-nav-wrapper .main .full .main-css {
  width: 33.33333333%;
}

.mid-nav-wrapper .main .full .upload {
  justify-content: center;
}

.mid-nav-wrapper .main .full .main-css {
  justify-content: flex-end;
}

.mid-nav-wrapper .main .no-css .main-subpage,
.mid-nav-wrapper .main .no-css .upload {
  width: 50%;
}

.mid-nav-wrapper .main .no-css .upload {
  justify-content: flex-end;
}

.mid-nav-wrapper .hamburger-main {
  display: none;
}

.mid-nav-wrapper .upload {
  display: inline-flex;
  align-items: center;
  gap: 1rem;
}

/* Tablet */
@media screen and (min-width: 768px) {
  .top-nav-wrapper .cssexy-logo-small,
  .top-nav-wrapper .main,
  .top-nav-wrapper .signed-in,
  .top-nav-wrapper .signed-out {
    display: none;
  }

  .top-nav-wrapper .cssexy-logo,
  .top-nav-wrapper .main-icon,
  .top-nav-wrapper .signed-in-icon,
  .top-nav-wrapper .signed-out-icon {
    display: flex;
    width: 33.33333333%;
    flex: 0 0 auto;
  }

  .top-nav-wrapper .main-icon {
    order: 1;
  }

  .top-nav-wrapper .cssexy-logo {
    order: 2;
    justify-content: center;
  }

  .top-nav-wrapper .signed-in-icon,
  .top-nav-wrapper .signed-out-icon {
    order: 3;
    justify-content: flex-end;
  }
}

/* Desktop */
@media screen and (min-width: 1024px) {
  .top-nav-wrapper .cssexy-logo-small,
  .top-nav-wrapper .main-icon,
  .top-nav-wrapper .signed-in-icon,
  .top-nav-wrapper .signed-out-icon {
    display: none;
  }

  .top-nav-wrapper .cssexy-logo,
  .top-nav-wrapper .main,
  .top-nav-wrapper .signed-in,
  .top-nav-wrapper .signed-out {
    display: flex;
    width: 33.33333333%;
    flex: 0 0 auto;
  }

  .top-nav-wrapper .cssexy-logo {
    order: 1;
    justify-content: flex-start;
  }

  .top-nav-wrapper .main {
    order: 2;
    justify-content: center;
  }

  .top-nav-wrapper .signed-in,
  .top-nav-wrapper .signed-out {
    order: 3;
    justify-content: flex-end;
  }
}

/* Mobile */
@media screen and (max-width: 767px) {
  .top-nav-wrapper .cssexy-logo,
  .top-nav-wrapper .main,
  .top-nav-wrapper .signed-in,
  .top-nav-wrapper .signed-out {
    display: none;
  }

  .top-nav-wrapper .cssexy-logo-small,
  .top-nav-wrapper .main-icon,
  .top-nav-wrapper .signed-in-icon,
  .top-nav-wrapper .signed-out-icon {
    display: flex;
    width: 33.33333333%;
    flex: 0 0 auto;
  }

  .top-nav-wrapper .main-icon {
    order: 1;
    justify-content: flex-start;
  }

  .top-nav-wrapper .cssexy-logo-small {
    order: 2;
    justify-content: center;
  }

  .top-nav-wrapper .signed-in-icon,
  .top-nav-wrapper .signed-out-icon {
    order: 3;
    justify-content: flex-end;
  }

  .mid-nav-wrapper .main {
    display: none;
  }

  .mid-nav-wrapper .hamburger-main {
    display: flex;
    justify-content: space-between;
  }
}

.bottom-nav {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
  padding: 1.5rem 2rem;
  font-size: 1.2rem;
  line-height: 1.75rem;
  background-color: var(--white);
}
