.tw-form:not(:first-child) {
  margin-top: 1.6rem;
}

.tw-form .form-field {
  margin-top: 1rem;
  padding: 0;
}

.tw-form .label {
  position: relative;
  color: var(--color-black);
  line-height: var(--awo-lh);
  font-size: var(--fs-s);
  display: inline-flex;
  align-items: center;
  font-weight: 500;
}

.tw-form .label.disabled {
  color: var(--black-l2);
}

.tw-form .label.disabled .overlapping.tiny,
.tw-form .label.disabled .input {
  color: var(--black-50);
}

.tw-form .label.disabled span {
  color: var(--black-50);
}

.tw-form .label .popover {
  z-index: 100;
}

.tw-form .label.for-select {
  width: calc(100% + 1.75rem);
}

.tw-form .overlapping {
  position: absolute;
  top: -1.2rem;
  left: 0;
  display: inline-block;
  z-index: 10;
  font-size: var(--fs-xs);
  line-height: var(--lh-xs);
  font-weight: 500;
  white-space: nowrap;
}

.tw-form .overlapping.tiny {
  font-size: var(--fs-xs);
  line-height: var(--lh-xs);
  color: var(--blue);
}

.tw-form .overlapping.tiny ~ .input {
  padding: 0.25rem 0.5rem 0.25rem;
}

.tw-form .overlapping.checkbox-label {
  position: relative;
  padding: 0;
  margin: 0 1rem 0 0;
  top: 0;
  left: 0;
}

.tw-form .edit-group-box {
  background-color: var(--grey-01);
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 1rem;
  margin-top: 0;
}

.tw-form .edit-group-box.inner-group {
  background-color: transparent;
}

.tw-form .edit-group-box .edit-group-left {
  display: flex;
  align-items: center;
  gap: 1.5rem 1rem;
  flex-wrap: wrap;
  position: relative;
  width: 100%;
}

.tw-form .edit-group-box .edit-group-left .add-btn {
  display: flex;
  margin-right: 2rem;
  min-height: 100%;
}

.tw-form .edit-group-box .edit-group-right {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
}

.tw-form .input,
.tw-form .select {
  display: inline-block;
  width: 100%;
  font-size: var(--fs-s);
  line-height: var(--lh-s);
  appearance: none;
  background-color: #fff;
  padding: 0.375rem 0.75rem;
  border: 0 solid rgba(107, 114, 128, 1);
  border-radius: 0.375rem;
  outline: 1px solid transparent;
  outline-offset: 2px;
  box-shadow: var(--shadow-form-element);
}

.tw-form .input[type="text"] {
  min-width: 5rem;
}

.tw-form .input[type="file"]::file-selector-button {
  background-color: var(--blue);
  color: white;
  border: none;
  cursor: pointer;
  padding: 0.625rem 1rem;
  appearance: button;
  border-top-left-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
}

.tw-form .select {
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: calc(100% - 0.3rem) 50%;
  background-size: 1.5rem 1.5rem;
  box-sizing: border-box;
  padding-right: 1.75rem;
}

.tw-form .select option {
  padding: 0.25rem 0.5rem;
}

.tw-form .input:focus,
.tw-form .select:focus {
  outline: 1px solid rgba(60, 188, 195, 0);
  outline-offset: 2px;
  box-shadow: var(--shadow-form-element-focus);
}

.tw-form .checkbox {
  display: inline-block;
  position: relative;
  padding: 0;
  border: 1px solid rgba(107, 114, 128, 1);
  margin: 0;
  appearance: none;
  outline: 1px solid rgba(60, 188, 195, 0);
  outline-offset: 12px;
}

.tw-form .checkbox {
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 0.25rem;
  background: rgba(255, 255, 255, 1);
  border-width: 0;
  cursor: pointer;
}

.tw-form .checkbox:checked {
  background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='white' d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3E%3C/svg%3E");
  background-color: var(--blue);
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}

.tw-form .checkbox:disabled {
  background-color: var(--black-l6);
  cursor: not-allowed;
  pointer-events: none;
}

.tw-form .checkbox.disabled:checked {
  background: url("data:image/svg+xml,%3Csvg viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%231d1d2c3f' d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3E%3C/svg%3E");
  background-color: var(--black-l6);
}

.tw-form .element-in-input {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  z-index: 10;
}

.tw-form .element-in-input.right {
  right: 0;
}

.tw-form .element-in-input.right.select {
  padding-left: 0.5rem;
  padding-right: 1.75rem;
}

.tw-form .element-in-input.left {
  left: 0;
  padding-left: 0.75rem;
}

.tw-form .element-in-input svg {
  fill: var(--svg-fill-gray);
  width: 1.25rem;
  height: 1.25rem;
  stroke: rgba(255, 255, 255, 1);
  background-color: transparent;
}

.tw-form .element-in-input svg.fill-blue {
  fill: var(--blue);
}

.tw-form .element-in-input .select {
  background-color: #fff;
}

.tw-form .input-msg {
  display: block;
}

.tw-form .input-msg.error-msg {
  color: var(--yellow);
}

.tw-form .input-msg.success-msg {
  color: var(--green);
}
