.sub-nav-ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  border-radius: 0.5rem;
}

.sub-nav-ul > li {
  display: flex;
  align-items: center;
}

.sub-nav-ul li:not(:first-child) {
  margin-left: 1rem;
}

.sub-nav-ul .separator {
  height: 1px;
  background-color: var(--black-l3);
  margin: 0.5rem 0;
}

.sub-nav-ul .sub-nav-link {
  display: block;
  font-size: 1.2rem;
  line-height: 1.75rem;
  color: var(--black);
  text-decoration: none;
  border-radius: 0.25rem;
  white-space: nowrap;
  background-color: transparent;
  border-width: 0;
  cursor: pointer;
}

.sub-nav-ul .sub-nav-link.active,
.sub-nav-ul .sub-nav-link:hover {
  color: var(--blue);
}

.sub-nav-ul.small .sub-nav-link {
  font-size: 1rem;
  line-height: 1.5rem;
}

.open-menu {
  position: relative;
}

.open-sub-menu-btn {
  appearance: button;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 2.5rem;
  height: 2.5rem;
  background-color: #fff;
  padding: 0.5rem;
  border-radius: 0.5rem;
  border-width: 0;
}

.open-sub-menu-btn:hover {
  background-color: var(--black-l4);
}

.open-sub-menu-btn:focus {
  box-shadow: 0 0 0 2px var(--black-l2);
}

.open-sub-menu-btn svg {
  fill: var(--blue);
}

.open-submenu-after-btn {
  position: absolute;
  top: 2.5rem;
  left: 0;
  z-index: 1000;
}

.open-submenu-after-btn .sub-nav-ul {
  width: 100%;
  min-width: 12rem;
  flex-direction: column;
  background-color: #fff;
  padding: 1rem;
  box-shadow: var(--shadow-modal);
}

.open-submenu-after-btn .sub-nav-ul li:not(:first-child) {
  margin-left: 0;
}

.open-submenu-after-btn .sub-nav-ul .sub-nav-link {
  position: relative;
  padding: 0.5rem 1rem;
  width: 100%;
  text-align: left;
  color: var(--blue);
  padding: 0.5rem 0.75rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.open-submenu-after-btn .sub-nav-ul .sub-nav-link.active {
  background-color: var(--blue);
  color: #fff;
  pointer-events: none;
  margin: 0.25rem 0;
}

.open-submenu-after-btn .sub-nav-ul .sub-nav-link:hover {
  background-color: var(--blue-l4);
  color: var(--blue);
}

.open-submenu-after-btn.right {
  top: 0;
  right: 0.5rem;
  left: auto;
}
