.error-box {
  max-width: 640px;
  margin: 0 auto;
  text-align: center;
  padding: 2rem 1rem;
}

.error-box .h1 {
  color: var(--blue);
  font-weight: 800;
  margin: 0 0 2rem;
  font-size: 4.5rem;
  line-height: 4.5rem;
}

.error-box .p-big {
  font-size: 1.875rem;
  line-height: 2.25rem;
  font-weight: 700;
  margin-bottom: 1rem;
}

.error-box .p-normal {
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-weight: 300;
  margin-bottom: 1rem;
}
