.section-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 1rem;
  font-size: 1rem;
  line-height: 1.8rem;
  color: var(--blue);
}

.section-title.black {
  color: var(--black);
}

.section-title.small {
  font-size: 1rem;
  line-height: 1.5rem;
  color: var(--black);
}

.section-title.small .button.round {
  padding: 0.25rem;
}

.section-title.medium {
  font-size: 1.1rem;
}

.section-title.large {
  font-size: 1.25rem;
  line-height: 2rem;
}

.section-title.right {
  justify-content: flex-end;
}

.section-title .title-text {
  white-space: nowrap;
}

.section-title .info-wrapper {
  display: inline-flex;
  align-items: center;
  padding: 0;
}
