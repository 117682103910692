.tawi-button {
  border: 0 solid rgba(60, 188, 195, 1);
  cursor: pointer;
  border-radius: 0.5rem;
  padding: 0.625rem 1.25rem;
  font-size: 1rem;
  font-weight: 500;
  --shadow-button-primary: 0 0 0 4px #78d1d5;
  --shadow-button-secondary: 0 0 0 4px #f0f0f5;
}

.tawi-button.primary {
  background-color: var(--blue);
  color: rgba(255, 255, 255, 1);
}

.tawi-button.primary:hover {
  background-color: var(--blue-d1);
}

.tawi-button.primary:focus {
  box-shadow: var(--shadow-button-primary);
}

.tawi-button.secondary {
  background-color: rgba(255, 255, 255, 1);
  border: 1px solid rgba(60, 188, 195, 1);
  border-color: var(--black-l3);
  color: var(--black-l1);
}

.tawi-button.secondary:hover {
  background-color: var(--black-l6);
  color: var(--black);
}

.tawi-button.secondary:focus {
  box-shadow: var(--shadow-button-secondary);
}
