.flowbite-vertical-tabs {
  display: flex;
  justify-content: center;
  gap: 2rem;
}

.flowbite-vertical-tabs .buttons {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.flowbite-vertical-tabs .buttons .tab-btn {
  display: inline-flex;
  background-color: rgba(240, 240, 245, 1);
  padding: 0.75rem 1rem;
  border-radius: 0.5rem;
  align-items: center;
  box-sizing: border-box;
  color: rgba(29, 29, 44, 1);
  font-size: 1rem;
  border-width: 0;
  cursor: pointer;
}

.flowbite-vertical-tabs .buttons .tab-btn svg {
  width: 1rem;
  height: 1rem;
  margin-right: 0.5rem;
  fill: rgba(29, 29, 44, 1);
}

.flowbite-vertical-tabs .buttons .tab-btn.active {
  color: rgba(255, 255, 255, 1);
  background-color: rgba(60, 188, 195, 1);
}

.flowbite-vertical-tabs .buttons .tab-btn.active svg {
  fill: rgba(255, 255, 255, 1);
}

.flowbite-vertical-tabs .buttons .tab-btn:not(.disabled):not(.active):hover {
  color: rgba(29, 29, 44, 1);
  background-color: rgba(216, 216, 229, 1);
}

.flowbite-vertical-tabs .buttons .tab-btn:not(.disabled):not(.active):hover svg {
  fill: rgba(29, 29, 44, 1);
}

.flowbite-vertical-tabs .buttons .tab-btn.disabled {
  color: rgba(169, 169, 198, 1);
  cursor: default;
}

.flowbite-vertical-tabs .buttons .tab-btn.disabled svg {
  fill: rgba(169, 169, 198, 1);
}

.flowbite-vertical-tabs .content {
  border-radius: 0.5rem;
}

.flowbite-vertical-tabs .content h3 {
  margin: 0 0 0.5rem;
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-weight: 700;
}

@media screen and (max-width: 950px) {
  .flowbite-vertical-tabs {
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }

  .flowbite-vertical-tabs .buttons {
    flex-direction: row;
    gap: 1rem;
  }
}
