.item-code-preview-box {
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
}

.item-code-preview-box .item-code-preview {
  background-color: var(--black-l6);
  border-radius: 0.5rem;
  border: 1px solid var(--black-l3);
  color: var(--black-l1);
  font-size: var(--fs-s);
  line-height: var(--lh-s);
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-feature-settings: normal;
  white-space: pre;
  overflow: auto;
  word-break: break-word;
  padding: 1rem;
  width: auto;
  max-width: 30rem;
  margin: 0;
}
