@keyframes openPopup {
  0% {
    transform: scaleY(0) scaleX(0);
    opacity: 0;
  }
  100% {
    transform: scaleX(1) scaleY(1);
    opacity: 1;
  }
}

.button-and-popup-container {
  display: inline-block;
  position: relative;
}

.button-and-popup-container .button {
  appearance: button;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 0.875rem;
  line-height: 1.25rem;
  border-width: 0;
  color: var(--black);
  fill: var(--black);
  background-color: transparent;
}

.button-and-popup-container .button svg {
  width: 1.25rem;
  height: 1.25rem;
  display: inline-block;
}

.button-and-popup-container .button .checked {
  margin-left: 0.375rem;
  display: inline-block;
  font-size: 0.875rem;
  line-height: 1.25rem;
  background-color: var(--black-l3);
  padding: 0.125rem 0.375rem;
  border-radius: 0.25rem;
}

.button-and-popup-container .popup-container {
  position: absolute;
  display: block;
  left: 0;
  z-index: 10;
  margin-top: 0.5rem;
  transform-origin: 0 0 0;
  transform: scaleX(1) scaleY(1);
  background-color: rgba(255, 255, 255, 1);
  border-radius: 0.375rem;
  border: 1px solid var(--black-l3);
  opacity: 1;
  transition: all 2s ease;
  box-shadow: var(--shadow-popup);
  animation: openPopup 0.2s linear;
  z-index: 1000;
}

.button-and-popup-container .popup-container.off {
  opacity: 0;
  display: none;
  transform: scaleX(0) scaleY(0);
}

.button-and-popup-container .popup-container.right {
  right: 0;
  left: auto;
}

.simple-menu {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.simple-menu .menu-item {
  text-decoration: none;
  display: block;
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 500;
  color: var(--black);
  text-align: left;
  white-space: nowrap;
  cursor: pointer;
}

.simple-menu .menu-item:hover {
  background-color: var(--blue-l4);
  color: var(--blue);
}

.simple-menu .menu-item.active {
  color: var(--blue);
  pointer-events: none;
}
