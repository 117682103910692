.tailwind-search-input-main .search-icon {
  position: absolute;
  padding-left: 0.75rem;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  top: 0;
  bottom: 0;
  left: 0;
}
.tailwind-search-input-main .search-icon svg {
  fill: rgba(169, 169, 198, 1);
  display: block;
  width: 1.25rem;
  height: 1.25rem;
  box-sizing: border-box;
}
.tailwind-search-input-main .input-search {
  padding: 1rem 1rem 1rem 2.5rem;
  border-radius: 0.5rem;
  width: 100%;
  display: block;
  border: 1px solid rgba(169, 169, 198, 1);
  background-color: rgba(240, 240, 245, 0.26666666666666666);
  font-size: 0.875rem;
  line-height: 1.25rem;
  box-sizing: border-box;
}
.tailwind-search-input-main .input-search::placeholder {
  white-space: pre-wrap;
}

.tailwind-search-input-main .input-search:focus {
  border-color: rgba(60, 188, 195, 1);
  outline: 1px solid rgba(60, 187, 194, 0);
  outline-offset: 2px;
  box-shadow: 0 0 0 1px rgba(60, 188, 195, 1);
}
.tailwind-search-input-main .button-search {
  position: absolute;
  top: 0.625rem;
  bottom: 0.625rem;
  right: 0.625rem;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  border-width: 0;
  background-color: rgba(60, 188, 195, 1);
  color: rgba(255, 255, 255, 1);
  font-size: 0.875rem;
  cursor: pointer;
  line-height: 1.25rem;
}
.tailwind-search-input-main .button-search:hover {
  background-color: rgba(51, 159, 165, 1);
}
.tailwind-search-input-main .button-search:disabled {
  cursor: default;
  background-color: var(--black-l3);
}
