.tooltip-container {
  position: absolute;
  transition: opacity 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 0;
  visibility: hidden;
  box-sizing: border-box;
  background-color: var(--black-l4);
  color: var(--black-l2);
  z-index: 1000;
  min-width: 8rem;
  max-width: 10rem;
  border-radius: 0.5rem;
  padding: 0.5rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.25rem;
  border: 1px solid var(--black-l2);
  box-shadow: var(--shadow-tooltip);
}

.tooltip-container.visible {
  opacity: 0.5;
  visibility: visible;
}

.tooltip-container .triangle-up,
.tooltip-container .triangle-right,
.tooltip-container .triangle-down,
.tooltip-container .triangle-left {
  position: absolute;
  display: inline-block;
  width: 0;
  height: 0;
}

.tooltip-container .triangle-up {
  top: 0;
  left: 50%;
  transform: translateX(-50%) translateY(-10px);
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid var(--black-l2);
}

.tooltip-container .triangle-down {
  bottom: 0;
  left: 50%;
  transform: translateX(-10px) translateY(10px);
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid var(--black-l2);
}

.tooltip-container .triangle-right {
  top: 50%;
  transform: translateY(-10px);
  border-left: 10px solid var(--black-l2);
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  right: -10px;
}

.tooltip-container .triangle-left {
  top: 50%;
  transform: translateY(-10px) translateX(-10px);
  left: 0;
  border-right: 10px solid var(--black-l2);
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
}

.tooltip-container.bottom-center {
  top: 100%;
  transform: translateY(10px) translateX(-50%);
  left: 50%;
}

.tooltip-container.bottom-left {
  top: 100%;
  transform: translateY(10px);
  left: 0;
  text-align: left;
}

.tooltip-container.bottom-left .triangle-up {
  left: 10px;
  transform: translateX(0) translateY(-10px);
}

.tooltip-container.bottom-right {
  top: 100%;
  transform: translateY(10px);
  right: 0;
  text-align: right;
}

.tooltip-container.bottom-right .triangle-up {
  left: auto;
  right: 10px;
  transform: translateX(0) translateY(-10px);
}

.tooltip-container.left-center {
  top: 50%;
  transform: translateY(-50%) translateX(-100%);
  left: -10px;
  text-align: right;
}

.tooltip-container.right-center {
  top: 50%;
  transform: translateY(-50%) translateX(100%);
  right: -10px;
  text-align: left;
}

.tooltip-container.top-center {
  bottom: 100%;
  transform: translateY(-10px) translateX(-50%);
  left: 50%;
}

.tooltip-container.top-left {
  bottom: 100%;
  transform: translateY(-10px);
  left: 0;
  text-align: left;
}

.tooltip-container.top-left .triangle-down {
  left: 10px;
  transform: translateX(0) translateY(10px);
}

.tooltip-container.top-right {
  bottom: 100%;
  transform: translateY(-10px);
  right: 0;
  text-align: right;
}

.tooltip-container.top-right .triangle-down {
  left: auto;
  right: 10px;
  transform: translateX(0) translateY(10px);
}

.tooltip-container-parent {
  position: relative;
}

.tooltip-container-parent:hover .tooltip-container {
  opacity: 1;
  visibility: visible;
}
