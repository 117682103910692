.flex {
  display: flex;
}

.flex-inline {
  display: inline-flex;
}

.flex.align-start,
.flex-inline.align-start {
  align-items: flex-start;
}

.flex.align-center,
.flex-inline.align-center {
  align-items: center;
}

.flex.align-end,
.flex-inline.align-end {
  align-items: flex-end;
}

.flex.align-stretch,
.flex-inline.align-stretch {
  align-items: stretch;
}

.flex.direction-column,
.flex-inline.direction-column {
  flex-direction: column;
}

.flex.gap-05r,
.flex-inline.gap-05r,
.flex.gap-05,
.flex-inline.gap-05 {
  gap: 0.5rem;
}

.flex.gap-1,
.flex-inline.gap-1 {
  gap: 1rem;
}

.flex.gap-2,
.flex-inline.gap-2 {
  gap: 2rem;
}

.flex.justify-space-between,
.flex-inline.justify-space-between {
  justify-content: space-between;
}

.flex.justify-start,
.flex-inline.justify-start {
  justify-content: flex-start;
}

.flex.justify-center,
.flex-inline.justify-center {
  justify-content: center;
}

.flex.justify-end,
.flex-inline.justify-end {
  justify-content: flex-end;
}

.flex.wrap,
.flex-inline.wrap {
  flex-wrap: wrap;
}

.flex .flex-1,
.flex-inline .flex-1 {
  flex: 1;
}

.flex-k50p {
  display: flex;
  gap: 1rem;
}

.flex-k50p > div {
  flex-basis: 50%;
}

@media screen and (max-width: 768px) {
  .flex-k50p {
    flex-direction: column;
  }
}

.flex-6633 {
  display: flex;
  gap: 1rem;
  justify-content: space-between;
}

.flex-6633 .flex-66 {
  flex-basis: 66.66%;
}

.flex-6633 .flex-33 {
  flex-basis: 33.33%;
}

.flex-6633 .flex-33 .section-title,
.flex-6633 .flex-33 .btn-row {
  justify-content: flex-end;
}

@media screen and (max-width: 950px) {
  .flex-6633 {
    flex-direction: column;
  }

  .flex-6633 .flex-66,
  .flex-6633 .flex-33 {
    flex-basis: 100%;
  }

  .flex-6633 .flex-66 {
    order: 2;
  }

  .flex-6633 .flex-33 {
    order: 1;
  }

  .flex-6633 .flex-33 .section-title,
  .flex-6633 .flex-33 .btn-row {
    justify-content: flex-start;
  }
}
