.display-hide2 {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  margin: 0 auto;
  overflow: auto;
}

.display-hide2 .tabs-with-underline,
.display-hide2 .default-tabs {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  margin-bottom: -1px;
  border-bottom: 1px solid var(--black-l3);
}

.display-hide2 .tabs-with-underline li,
.display-hide2 .default-tabs li {
  display: block;
  margin-right: 0.5rem;
}

.display-hide2 .tabs-with-underline li button,
.display-hide2 .default-tabs li button {
  display: inline-block;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 1);
  padding: 1rem;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  border: 1px solid transparent;
  color: var(--black);
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 500;
}

.display-hide2 .tabs-with-underline li button:not(.disabled):not(.active):hover,
.display-hide2 .default-tabs li button:not(.disabled):not(.active):hover {
  background-color: var(--black-l4);
}

.display-hide2 .tabs-with-underline li button.active,
.display-hide2 .default-tabs li button.active {
  background-color: var(--black-l4);
  color: var(--blue);
}

.display-hide2 .tabs-with-underline li button.disabled,
.display-hide2 .default-tabs li button.disabled {
  cursor: not-allowed;
  color: var(--black-l3);
}

.display-hide2 .tabs-with-underline {
  border-bottom: 1px solid transparent;
}

.display-hide2 .tabs-with-underline li button {
  padding: 1rem 1rem 0.5rem 1rem;
  border-bottom: 2px solid #fff;
}

.display-hide2 .tabs-with-underline li button:not(.disabled):not(.active):hover {
  border-bottom: 2px solid var(--blue);
  background-color: transparent;
}

.display-hide2 .tabs-with-underline li button.active {
  background-color: transparent;
  border-bottom: 2px solid rgba(60, 188, 195, 1);
  color: rgba(60, 188, 195, 1);
}

.display-hide2 .tabs-with-underline li button.disabled {
  cursor: not-allowed;
  color: rgba(169, 169, 198, 1);
}

.display-hide2 .content {
  flex: 1 0 100%;
  padding: 1rem;
  min-width: 250px;
  min-height: 300px;
}
