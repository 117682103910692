.breadcrumb-wrapper {
  display: flex;
  padding: 1rem 2rem;
  justify-content: center;
}

.breadcrumb-nav {
  display: flex;
}

.breadcrumb-nav .ol {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: inline-flex;
  align-items: center;
}

.breadcrumb-nav .ol .li {
  display: inline-flex;
  align-items: center;
}

.breadcrumb-nav .ol .li .a {
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  color: var(--black);
  font-size: 1.25rem;
  font-weight: 500;
  cursor: default;
}

.breadcrumb-nav .ol .li .a.link {
  color: var(--blue);
  font-weight: 500;
  cursor: pointer;
}

.breadcrumb-nav .ol .li .a .svg {
  margin-right: 1rem;
}

.breadcrumb-nav .ol .li .svg {
  width: 2rem;
  height: 2rem;
  display: block;
}

.breadcrumb-nav .ol .li .svg.path {
  fill: transparent;
  stroke: var(--black);
  stroke-linecap: round;
  stroke-linejoin: round;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
