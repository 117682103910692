.error-or-success-msg {
  display: block;
  margin-top: 0.5rem;
  font-size: var(--fs-s);
  line-height: var(--lh-s);
}

.error-or-success-msg.error {
  color: var(--yellow);
}

.error-or-success-msg.success {
  color: var(--green);
}
