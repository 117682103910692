.border-b-l4 {
  padding-bottom: 1rem;
  border-bottom: 1px solid var(--black-l4);
  margin-bottom: 1rem;
}

.position-relative {
  position: relative;
}

.text-align-center {
  text-align: center;
}

.display-none {
  display: none;
}

.sr-only {
  position: absolute;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.w-100p {
  width: 100%;
}

.w-10r {
  width: 10rem;
}
.w-16r {
  width: 16rem;
}

.m-1r {
  margin: 1rem;
}

.mt-2 {
  margin-top: 10px;
}
.mt-3 {
  margin-top: 20px;
}
.mt-05r {
  margin-top: 0.5rem;
}
.mt-1r {
  margin-top: 1rem;
}
.mt-2r {
  margin-top: 2rem;
}
.mt-3r {
  margin-top: 3rem;
}

.mr-2 {
  margin-right: 10px !important;
}
.mr-1r {
  margin-right: 1rem;
}
.mr-2r {
  margin-right: 2rem;
}

.mb-1r {
  margin-bottom: 1rem;
}
.mb-2r {
  margin-bottom: 2rem;
}
.mb-2 {
  margin-bottom: 10px !important;
}
.mb-4 {
  margin-bottom: 30px !important;
}

.ml-2 {
  margin-left: 10px;
}
.ml-05r {
  margin-left: 0.5rem;
}
.ml-1r {
  margin-left: 1rem;
}
.ml-2r {
  margin-left: 2rem;
}
.p-0 {
  padding: 0 !important;
}
.p-1r {
  padding: 1rem;
}
.p-12r {
  padding: 1rem 2rem;
}
.p-2 {
  padding: 10px;
}

.p-2r {
  padding: 2rem;
}

.p-1r2r {
  padding: 1rem 2rem;
}
.pr-3r {
  padding-right: 3rem;
}

.pb-1r {
  padding-bottom: 1rem;
}
.pb-2r {
  padding-bottom: 2rem;
}

.pl-15r {
  padding-left: 1.5rem;
}
.pl-2r {
  padding-left: 2rem;
}
