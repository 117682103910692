.sign-in-up {
  font-size: var(--fs-m);
  line-height: var(--lh-m);
  color: var(--black-l1);
  max-width: 40rem;
  margin: 2rem auto 0 auto;
}

.sign-in-up .input-box {
  max-width: 24rem;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 1rem;
}

.sign-in-up .sign-title {
  text-align: center;
  border-bottom: 1px solid var(--black-l4);
}

.sign-in-up .info-box {
  text-align: center;
  font-size: var(--fs-s);
  line-height: var(--lh-s);
  padding: 0 2rem 2rem;
}

.sign-in-up .sign-switch {
  text-align: center;
  margin: 2rem 0;
}

.sign-in-up .sign-in-up-btn {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 2rem 0;
}
