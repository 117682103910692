.picker {
  position: relative;
}

.swatch {
  width: 2rem;
  height: 2rem;
  border-radius: 0.5rem;
  border: 0.4rem solid #fff;
  box-shadow: var(--shadow-input-no-inset);
  cursor: pointer;
  background-color: var(--black-l2);
}

.swatch.disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.color-picker-popover {
  position: absolute;
  top: calc(100% + 2px);
  left: 0;
  z-index: 100;
  padding-bottom: 2rem;
}

.color-picker-popover .react-colorful__last-control {
  border-radius: 0;
}

.color-picker-popover .wrapper {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  background-color: white;
  border-radius: 9px;
}

.color-picker-popover .wrapper .hex-color {
  padding: 0 0.75rem 1rem;
  text-align: center;
  border-radius: 0 0 8px 8px;
}

.color-picker-popover .wrapper .hex-color input[type="text"] {
  min-width: 4rem;
}

.color-picker-popover .wrapper .hex-color small {
  display: block;
  text-align: left;
  margin-top: 0.5rem;
}

.color-picker-popover .wrapper .presets {
  padding: 0 0.75rem 1rem;
  display: flex;
  flex-wrap: wrap;
  gap: 0.375rem;
}

.color-picker-popover .wrapper .presets button {
  display: inline-block;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 0.375rem;
  border: 3px solid #fff;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), inset 0 0 0 1px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}
