.gallery-container .title,
.gallery-container .sub-title {
  text-align: center;
  color: var(--black-75);
}
.gallery-container .title {
  font-size: var(--fs-xl);
  line-height: var(--lh-l);
}
.gallery-container .sub-title {
  font-size: var(--fs-m);
  line-height: var(--lh-m);
}

.gallery-box {
  margin: 0 auto;
  overflow: hidden;
}

.gallery-item {
  background-color: white;
  border-radius: var(--border-radius-2);
  box-shadow: var(--box-shadow-default);
  outline: none;
  transition: 0.3s;
  position: relative;

  margin: 1rem;

  aspect-ratio: 16 / 9;

  overflow: hidden;
}

.gallery-item .overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;

  padding: 2rem;

  background: transparent;
  z-index: 10;
  border-radius: var(--border-radius-2);
  color: transparent;
}

.gallery-item .overlay:hover {
  background-color: var(--black-10);
  cursor: pointer;
  color: #fff;
  font-size: 1.1rem;
  font-weight: 600;
  text-shadow: 0 0 2px var(--black);
}

.gallery-item iframe {
  border-radius: var(--border-radius-2);
  height: 100%;
  outline: none;
  border-width: 0;
  pointer-events: none;
  overflow: hidden;
}
