.account-main {
  background-color: var(--black-l4);
  height: 100%;
}

.account-main .account-wrapper {
  margin: 0 auto;
  padding: 3rem 1.5rem;
  display: flex;
  justify-content: center;
}

.login-inner {
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  width: 100%;
  min-width: 10rem;
  padding: 2rem 1rem;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: var(--tw-shadow);
}

.login-inner .h1 {
  margin: 0;
  font-weight: 700;
  color: var(--black);
  font-size: var(--fs-l);
  line-height: var(--lh-l);
}

.login-inner .login-form .login-label {
  display: block;
  position: relative;
}

.login-inner .login-form .login-label-inline {
  display: flex;
  align-items: center;
}
.login-inner .login-form .login-label-inline span {
  margin-left: 0.5rem;
}


.login-inner .login-form .login-label-span {
  display: block;
  margin-bottom: 0.5rem;
  font-size: var(--fs-s);
  line-height: var(--lh-s);
  font-weight: 500;
  color: var(--black);
}

.login-inner .login-form .input-wrapper {
  position: relative;
}

.login-inner .login-form .login-input,
.login-inner .login-form .login-checkbox {
  appearance: none;
  display: block;
}

.login-inner .login-form .login-input {
  width: 100%;
  border-radius: 0.5rem;
  border: 1px solid var(--black-l3);
  background-color: var(--black-l6);
  padding: 0.625rem;
  color: var(--black);
  outline: 2px solid rgba(60, 188, 195, 0);
  outline-offset: 2px;
  font-size: var(--fs-base);
  line-height: var(--lh-base);
}

.login-inner .login-form .login-input:focus {
  --shadow-input-focus: 0 0 0 2px rgba(255, 255, 255, 1), 0 0 0 4px #3cbcc3;
  box-shadow: var(--shadow-input-focus);
  border-color: var(--blue);
}

.login-inner .login-form .login-checkbox {
  width: 1rem;
  height: 1rem;
}

.login-inner .login-form .login-checkbox:checked {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 16 16' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.207 4.793a1 1 0 0 1 0 1.414l-5 5a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L6.5 9.086l4.293-4.293a1 1 0 0 1 1.414 0z'/%3E%3C/svg%3E");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-color: var(--blue);
}

.login-inner .login-form .input-svg {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  z-index: 10;
}

.login-inner .login-form .input-svg.right {
  right: 0;
  padding-right: 0.5rem;
}

.login-inner .login-form .input-svg.right.select {
  padding-left: 0.5rem;
  padding-right: 1.75rem;
}

.login-inner .login-form .input-svg.left {
  left: 0;
  padding-left: 0.75rem;
}

.login-inner .login-form .input-svg svg {
  fill: var(--svg-fill-gray);
  width: 1.25rem;
  height: 1.25rem;
  stroke: rgba(255, 255, 255, 1);
  background-color: transparent;
}

.login-inner .login-form .input-svg svg.fill-blue {
  fill: var(--blue);
}

.login-inner .login-form .login-button {
  appearance: button;
  display: inline-block;
  width: 100%;
  padding: 0.625rem 1.25rem;
  border-radius: 0.5rem;
  border: 0 solid rgba(0, 0, 0, 1);
  cursor: pointer;
  font-size: var(--fs-base);
  line-height: var(--lh-base);
}

.login-inner .login-form .login-button.primary {
  background-color: var(--blue);
  color: rgba(255, 255, 255, 1);
}

.login-inner .login-form .login-button.primary:hover {
  background-color: var(--blue-d1);
}

.login-inner .login-form .login-button.disabled {
  color: var(--black-l1);
  background-color: var(--black-l6);
  border: 1px solid var(--black-l3);
  box-shadow: none;
  cursor: default;
  pointer-events: none;
}

.login-inner .login-form .login-link {
  display: inline-block;
  text-decoration: none;
  color: var(--blue);
  font-weight: 500;
  cursor: pointer;
}

.login-inner .login-form .login-link:hover {
  text-decoration: underline;
}

@media all and (min-width: 640px) {
  .login-inner {
    max-width: 28rem;
    border-radius: 0.5rem;
  }
}
