.html-tree-container {
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 0.5rem;
}

.html-tree-container .html-tree-row {
  position: relative;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
  padding: 0 4rem 0 0.25rem;
}
