.showLoader {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.1);
  z-index: 10001;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner {
  display: inline-block;
}

.spinner div {
  transform-origin: 40px 40px 0;
  animation: spinner 1.2s linear infinite;
}

.spinner div::after {
  background-color: rgba(60, 188, 195, 1);
  border-radius: 20%;
  width: 6px;
  height: 18px;
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  content: "";
}

.spinner div:nth-child(1) {
  animation-delay: -1.1s;
  transform: rotateZ(0deg);
}

.spinner div:nth-child(2) {
  animation-delay: -1s;
  transform: rotateZ(30deg);
}

.spinner div:nth-child(3) {
  animation-delay: -0.9s;
  transform: rotateZ(60deg);
}

.spinner div:nth-child(4) {
  animation-delay: -0.8s;
  transform: rotateZ(90deg);
}

.spinner div:nth-child(5) {
  animation-delay: -0.7s;
  transform: rotateZ(120deg);
}

.spinner div:nth-child(6) {
  animation-delay: -0.6s;
  transform: rotateZ(150deg);
}

.spinner div:nth-child(7) {
  animation-delay: -0.5s;
  transform: rotateZ(180deg);
}

.spinner div:nth-child(8) {
  animation-delay: -0.4s;
  transform: rotateZ(210deg);
}

.spinner div:nth-child(9) {
  animation-delay: -0.3s;
  transform: rotateZ(240deg);
}

.spinner div:nth-child(10) {
  animation-delay: -0.2s;
  transform: rotateZ(270deg);
}

.spinner div:nth-child(11) {
  animation-delay: -0.1s;
  transform: rotateZ(300deg);
}

.spinner div:nth-child(12) {
  animation-delay: 0;
  transform: rotateZ(330deg);
}

@keyframes spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.lds {
  position: relative;
  display: inline-block;
  width: 80px;
  height: 80px;
}
