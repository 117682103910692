.tree-btn {
  height: 2rem;
  width: auto;
}

.tree-btn.level0 {
  width: 2rem;
}

.wave {
  overflow: hidden;
}

.wave::before {
  border-radius: 100px;
  background-color: rgba(60, 188, 195, 0.25);
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -1px;
  margin-left: -1px;
  width: 2px;
  height: 2px;
  z-index: 2;
  transition: all 0.5s;
  content: "";
}

.now.wave::before {
  margin-top: -50px;
  margin-left: -50px;
  width: 100px;
  height: 100px;
  content: "";
}

.btn {
  transition: all 0.3s ease;
  align-items: center;
  display: inline-block;
  height: 36px;
  background-color: rgba(60, 188, 195, 1);
  color: rgba(255, 255, 255, 1);
  line-height: 36px;
  text-align: center;
  border: none;
  outline: none;
  border-radius: 0.375rem;
  padding: 0.25rem 0.35rem;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 1rem;
  cursor: pointer;
}

.btn:not(.disabled):hover {
  background-color: rgba(88, 199, 205, 1);
}

.shadow {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
              0 1px 5px 0 rgba(0, 0, 0, 0.12),
              0 3px 1px -2px rgba(0, 0, 0, 0.2);
}

.shadow:hover {
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.14),
              0 1px 7px 0 rgba(0, 0, 0, 0.12),
              0 3px 1px -1px rgba(0, 0, 0, 0.2);
}

.waves-effect {
  overflow: hidden;
  cursor: pointer;
  position: relative;
  z-index: 1;
  display: inline-block;
}

.waves-effect::after {
  opacity: 1;
  transform: scaleX(0) scaleY(0);
  border-radius: 50%;
  background-color: rgba(103, 214, 220, 1);
  width: 36px;
  height: 36px;
  position: absolute;
  right: calc(50% - 18px);
  top: 0px;
  content: "";
}

.ripple {
  background-color: rgba(103, 214, 220, 1);
}

.ripple.waves-effect::after {
  opacity: 0;
  transform: scaleX(4) scaleY(4);
  content: "";
  transition: all 0.8s ease;
}

.toolbar-button {
  position: relative;
  margin: 0;
  padding: 0;
  width: 2.4rem;
  height: 2.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-width: 0;
  border-radius: 0.375rem;
  background-color: #fff;
  box-shadow: var(--shadow-form-element);
  transition: all 0.3s ease;
}

.toolbar-button svg {
  width: 1.4rem;
  height: auto;
  fill: var(--blue);
}

.toolbar-button:hover {
  background-color: var(--blue-l3);
  box-shadow: var(--shadow-form-element-hover);
}

.toolbar-button.active {
  background-color: var(--blue-l3);
}


.toolbar-button.orange:hover {
  background-color: var(--yellow-25);
}
.toolbar-button.orange svg {
  fill: var(--yellow);
}

.toolbar-button.disabled {
  color: var(--black-l1);
  background-color: var(--black-l6);
  border: 1px solid var(--black-l3);
  box-shadow: none;
}

.toolbar-button.disabled svg {
  fill: var(--black-l2);
}

.btn-color,
.toolbar-button.btn-color {
  color: var(--color);
  background: var(--background-color);
  border-color: var(--border-color);
  transition: all 0.5s ease;
  box-shadow: none;
  border-width: 1px;
  border-style: solid;
}

.btn-color svg,
.toolbar-button.btn-color svg {
  fill: var(--color);
}

.btn-color.active,
.btn-color:hover,
.toolbar-button.btn-color.active,
.toolbar-button.btn-color:hover {
  color: var(--active-color);
  border-color: var(--active-border-color);
  background: var(--active-background-color);
}

.btn-color.active svg,
.toolbar-button.btn-color.active svg {
  fill: var(--active-color);
}

.btn-teal {
  --color: var(--white);
  --background-color: var(--blue);
  --border-color: var(--blue);
  --active-color: var(--white);
  --active-background-color: var(--blue-d1);
  --active-border-color: var(--blue-d2);
  --focus-box-shadow-color: var(--blue-d2);
}

.btn-teal-light {
  --color: var(--black);
  --background-color: var(--blue-l1);
  --border-color: var(--blue-l1);
  --active-color: var(--white);
  --active-background-color: var(--blue-50);
  --active-border-color: var(--blue);
  --focus-box-shadow-color: var(--blue-50);
}

.btn-outline-teal {
  --color: var(--blue);
  --background-color: var(--white);
  --border-color: var(--blue);
  --active-color: var(--blue-d1);
  --active-background-color: var(--blue-l3);
  --active-border-color: var(--blue-d1);
  --focus-box-shadow-color: var(--blue-d1);
}

.btn-orange {
  --color: var(--white);
  --background-color: var(--yellow);
  --border-color: var(--yellow);
  --active-color: var(--white);
  --active-background-color: var(--yellow-d1);
  --active-border-color: var(--yellow-d1);
}

.btn-outline-orange {
  --color: var(--yellow);
  --background-color: var(--white);
  --border-color: var(--yellow);
  --active-color: var(--white);
  --active-background-color: var(--yellow-l2);
  --active-border-color: var(--yellow-d1);
  --focus-box-shadow-color: var(--yellow-d1);
}

.btn-outline-red {
  --color: var(--red);
  --background-color: var(--white);
  --border-color: var(--red);
  --active-color: var(--white);
  --active-background-color: var(--red);
  --active-border-color: var(--red);
  --focus-box-shadow-color: var(--red-50);
}

.btn-outline-red:hover {
  --color: var(--white);
  --background-color: var(--red);
  --border-color: var(--red);
}

.btn-color.disabled,
.btn-color.disabled:hover,
.toolbar-button.btn-color.disabled,
.toolbar-button.btn-color.disabled:hover {
  --color: var(--black-l1);
  --background-color: var(--black-l6);
  --border-color: var(--black-l2);
  --active-color: var(--black-l1);
  --active-background-color: var(--black-l6);
  --active-border-color: var(--black-l2);
  --focus-box-shadow-color: var(--black-l3);
  pointer-events: none;
  cursor: not-allowed;
}

.btn-color.disabled svg,
.toolbar-button.btn-color.disabled svg {
  fill: var(--black-l2);
}
