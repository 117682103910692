.dropdown-auto-complete {
  position: absolute;
  margin: 0;
  display: block;
  z-index: 10;
  border-radius: 0.5rem;
  background-color: rgba(255, 255, 255, 1);
  box-shadow:
    0 1px 3px rgba(0, 0, 0, 0.10196078431372549),
    0 1px 2px -1px rgba(0, 0, 0, 0.10196078431372549);
  animation: drop 0.4s cubic-bezier(0.5, 0, 0.5, 1) forwards;
}
.dropdown-auto-complete ul {
  list-style: none;
  margin: 0;
  padding: 0;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  overflow-y: auto;
  text-align: left;
}
.dropdown-auto-complete ul li {
  line-height: 1.25rem;
  font-size: 0.875rem;
  padding: 0.5rem 1rem;
  color: rgba(117, 117, 117, 1);
}
.dropdown-auto-complete ul li:hover {
  background-color: rgba(0, 0, 0, 0.10196078431372549);
}

@keyframes drop {
  0% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(3px);
  }
}
