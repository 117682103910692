.snippet-details-box h2 {
  text-align: center;
}

.snippet-details {
  position: relative;
  border: 1px solid var(--color-outline-light);
  border-radius: var(--border-radius-2);
  padding: 20px 40px;
}

.snippet-details .title {
  display: flex;
  justify-content: space-between;
  font-size: var(--fs-l);
  margin-bottom: 10px;
}
