.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  position: relative;
  align-items: flex-end;
  width: 100%;
}
.row.align-start {
  align-items: flex-start;
}
.row.align-center {
  align-items: center;
}

.row > * {
  max-width: 100%;
  flex-shrink: 0;
}
.col {
  flex: 1 0 0;
}
.col-auto {
  width: auto;
  flex: 0 0 auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.3333333333%;
}
.col-2 {
  flex: 0 0 auto;
  width: 16.6666666667%;
}
.col-3 {
  flex: 0 0 auto;
  width: 25%;
}
.col-4 {
  width: 33.3333333333%;
  flex: 0 0 auto;
}
.col-5 {
  width: 41.6666666667%;
  flex: 0 0 auto;
}
.col-6 {
  width: 50%;
  flex: 0 0 auto;
}
.col-7 {
  width: 58.3333333333%;
  flex: 0 0 auto;
}
.col-8 {
  width: 66.6666666667%;
  flex: 0 0 auto;
}
.col-9 {
  width: 75%;
  flex: 0 0 auto;
}
.col-10 {
  width: 83.3333333333%;
  flex: 0 0 auto;
}
.col-11 {
  width: 91.6666666667%;
  flex: 0 0 auto;
}
.col-12 {
  width: 100%;
  flex: 0 0 auto;
}

.container {
  width: 100%;
  padding-right: 2rem;
  padding-left: 2rem;
  box-sizing: border-box;
  margin-right: auto;
  margin-left: auto;
}

@media all and (min-width: 640px) {
  .container {
    max-width: 40rem;
  }
  .col-sm {
    flex: 1 0 0;
  }
  .col-sm-1 {
    width: 8.3333333333%;
    flex: 0 0 auto;
  }
  .col-sm-2 {
    width: 16.6666666667%;
    flex: 0 0 auto;
  }
  .col-sm-3 {
    width: 25%;
    flex: 0 0 auto;
  }
  .col-sm-4 {
    width: 33.3333333333%;
    flex: 0 0 auto;
  }
  .col-sm-5 {
    width: 41.6666666667%;
    flex: 0 0 auto;
  }
  .col-sm-6 {
    width: 50%;
    flex: 0 0 auto;
  }
  .col-sm-7 {
    width: 58.3333333333%;
    flex: 0 0 auto;
  }
  .col-sm-8 {
    width: 66.6666666667%;
    flex: 0 0 auto;
  }
  .col-sm-9 {
    width: 75%;
    flex: 0 0 auto;
  }
  .col-sm-10 {
    width: 83.3333333333%;
    flex: 0 0 auto;
  }
  .col-sm-11 {
    width: 91.6666666667%;
    flex: 0 0 auto;
  }
  .col-sm-12 {
    width: 100%;
    flex: 0 0 auto;
  }
  .col-sm-auto {
    width: auto;
    flex: 0 0 auto;
  }
}

@media all and (min-width: 768px) {
  .container {
    max-width: 45rem;
  }
  .col-md-1 {
    width: 8.3333333333%;
    flex: 0 0 auto;
  }
  .col-md-2 {
    width: 16.6666666667%;
    flex: 0 0 auto;
  }
  .col-md-3 {
    width: 25%;
    flex: 0 0 auto;
  }
  .col-md-4 {
    width: 33.3333333333%;
    flex: 0 0 auto;
  }
  .col-md-5 {
    width: 41.6666666667%;
    flex: 0 0 auto;
  }
  .col-md-6 {
    width: 50%;
    flex: 0 0 auto;
  }
  .col-md-7 {
    width: 58.3333333333%;
    flex: 0 0 auto;
  }
  .col-md-8 {
    width: 66.6666666667%;
    flex: 0 0 auto;
  }
  .col-md-9 {
    width: 75%;
    flex: 0 0 auto;
  }
  .col-md-10 {
    width: 83.3333333333%;
    flex: 0 0 auto;
  }
  .col-md-11 {
    width: 91.6666666667%;
    flex: 0 0 auto;
  }
  .col-md-12 {
    width: 100%;
    flex: 0 0 auto;
  }
  .col-md-auto {
    width: auto;
    flex: 0 0 auto;
  }

  .offset-md-3 {
    margin-left: 25% !important;
  }
}

@media all and (min-width: 950px) {
  .container {
    max-width: 50rem;
  }
}

@media all and (min-width: 1024px) {
  .container {
    max-width: 60rem;
  }
  .col-lg-1 {
    width: 8.3333333333%;
    flex: 0 0 auto;
  }
  .col-lg-2 {
    width: 16.6666666667%;
    flex: 0 0 auto;
  }
  .col-lg-3 {
    width: 25%;
    flex: 0 0 auto;
  }
  .col-lg-4 {
    width: 33.3333333333%;
    flex: 0 0 auto;
  }
  .col-lg-5 {
    width: 41.6666666667%;
    flex: 0 0 auto;
  }
  .col-lg-6 {
    width: 50%;
    flex: 0 0 auto;
  }
  .col-lg-7 {
    width: 58.3333333333%;
    flex: 0 0 auto;
  }
  .col-lg-8 {
    width: 66.6666666667%;
    flex: 0 0 auto;
  }
  .col-lg-9 {
    width: 75%;
    flex: 0 0 auto;
  }
  .col-lg-10 {
    width: 83.3333333333%;
    flex: 0 0 auto;
  }
  .col-lg-11 {
    width: 91.6666666667%;
    flex: 0 0 auto;
  }
  .col-lg-12 {
    width: 100%;
    flex: 0 0 auto;
  }
  .col-lg-auto {
    width: auto;
    flex: 0 0 auto;
  }
}

@media all and (min-width: 1280px) {
  .container {
    max-width: 70rem;
  }
  .col-xl-1 {
    width: 8.3333333333%;
    flex: 0 0 auto;
  }
  .col-xl-2 {
    width: 16.6666666667%;
    flex: 0 0 auto;
  }
  .col-xl-3 {
    width: 25%;
    flex: 0 0 auto;
  }
  .col-xl-4 {
    width: 33.3333333333%;
    flex: 0 0 auto;
  }
  .col-xl-5 {
    width: 41.6666666667%;
    flex: 0 0 auto;
  }
  .col-xl-6 {
    width: 50%;
    flex: 0 0 auto;
  }
  .col-xl-7 {
    width: 58.3333333333%;
    flex: 0 0 auto;
  }
  .col-xl-8 {
    width: 66.6666666667%;
    flex: 0 0 auto;
  }
  .col-xl-9 {
    width: 75%;
    flex: 0 0 auto;
  }
  .col-xl-10 {
    width: 83.3333333333%;
    flex: 0 0 auto;
  }
  .col-xl-11 {
    width: 91.6666666667%;
    flex: 0 0 auto;
  }
  .col-xl-12 {
    width: 100%;
    flex: 0 0 auto;
  }
  .col-xl-auto {
    width: auto;
    flex: 0 0 auto;
  }
}

.grid-2 {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}
.grid-2 > div {
  width: calc(50% - 10px);
  flex: 0 0 auto;
}
.grid-2 > div:first-child {
  margin-right: 10px;
}
.grid-2 > div:last-child {
  margin-left: 10px;
}

@media all and (max-width: 992px) {
  .grid-2 > div {
    width: 100%;
  }
  .grid-2 > div:first-child {
    margin-right: 0;
    margin-bottom: 20px;
  }
  .grid-2 > div:last-child {
    margin-left: 0;
  }
}
