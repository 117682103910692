.tree-table {
  overflow: auto;
  position: relative;
  font-size: var(--fs-s);
  line-height: var(--lh-s);
  min-height: 200px;
  max-height: 400px;

  padding-top: 1rem;
}

.tree-table.row {
  align-items: flex-start;
}

.tree-table tr,
.tree-table td {
  display: flex;
  flex-wrap: nowrap;
}

.tree-table td {
  padding: 0.25rem;
}

.add-buttons-box {
  display: flex;
  align-items: flex-start;
}

.add-buttons-box .btn-row {
  margin-left: 2rem;
}

@media (max-width: 768px) {
  .add-buttons-box {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
  }

  .add-buttons-box .btn-row {
    margin-left: 0;
  }
}
