.preview-board {
  position: relative;
  width: 100%;
}

.code-preview-box {
  display: flex;
  justify-content: center;
}

.preview-box {
  position: relative;
  display: block;
  height: 100%;
  overflow-x: auto;
  overflow-y: hidden;
}

.display-hide.preview-board {
  max-width: 100%;
  margin-bottom: 20px;
}

.display-hide.preview-board .links .tab-link {
  background-color: var(--grey-01);
}

.settings {
  position: relative;
}

.code-box {
  position: relative;
  overflow-wrap: break-word;
  background-color: var(--black-l6);
  color: var(--black-l1);
  box-sizing: border-box;
  font-size: var(--fs-s);
  line-height: var(--lh-s);
  cursor: default;
}

.code-box .title {
  display: flex;
  justify-content: space-between;
  border: 1px solid var(--black-l4);
  height: 3rem;
}

.code-box .title .tab {
  background-color: var(--black-l4);
  padding: 0.5rem 0.75rem;
  display: inline-flex;
  align-items: center;
}

.code-box .title .tab.button {
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
}

.code-box .title .tab.button:hover {
  background-color: var(--black-l3);
}

.code-box .title .tab.button svg {
  width: 0.875rem;
  height: 0.875rem;
  margin-right: 0.5rem;
}

.code-box .copy-to-clipboard-btn {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  padding-top: 1rem;
  padding-right: 2rem;
}

.code-box .html-css-code {
  white-space: pre;
  font-family: monospace;
  height: calc(100% - 3rem);
  margin: 0;
  position: relative;
  box-sizing: border-box;
  text-align: left;
  overflow: auto;
  padding: 0 2rem 1rem 2rem;
}

.i-frame-box {
  margin-left: auto;
  margin-right: auto;
  transition: 0.3s;
}

.drawing-board {
  background-image: repeating-linear-gradient(rgba(29, 29, 44, 0.1), rgba(29, 29, 44, 0.1) 1px, rgba(0, 255, 0, 0) 1px, rgba(255, 255, 0, 0) 10px),
    repeating-linear-gradient(90deg, rgba(29, 29, 44, 0.1), rgba(29, 29, 44, 0.1) 1px, rgba(235, 166, 63, 0) 1px, rgba(235, 166, 63, 0) 10px);
  border: 1px solid rgba(29, 29, 44, 0.1);
  box-sizing: content-box;
}
