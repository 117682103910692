.preview-toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;

  padding: 0.5rem 0 1rem;
}

.bg-light-grey {
  background-color: var(--black-l4);
}



