.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
  max-height: 100%;
  width: 100%;
  background-color: var(--black-25);
  padding-bottom: 4rem;
}

.modal-container .modal-container-inner {
  max-height: 100%;
  position: relative;
  padding: 4rem 1rem 1rem;
  max-width: 42rem;
  width: 100%;
}

.modal-container .modal-container-inner .modal-box {
  box-shadow: none;
}

.oc-btn-and-modal-box {
  display: inline-flex;
  flex-direction: column;
  position: relative;
}

.oc-btn-and-modal-box .oc-btn {
  display: inline-flex;
  align-items: center;
}

.oc-btn-and-modal-box .svg-info {
  display: inline-block;
  fill: rgba(60, 188, 195, 1);
  border-radius: 100%;
  background-color: #fff;
  cursor: pointer;
  width: 1.5rem;
  height: 1.5rem;
}

.oc-btn-and-modal-box .svg-info:hover {
  background-color: var(--blue-l3);
}

.oc-btn-and-modal-box.warning .svg-info {
  fill: var(--yellow);
}

.oc-btn-and-modal-box.warning .svg-info:hover {
  background-color: var(--yellow-l2);
}

.oc-btn-and-modal-box.warning .modal-header h3 {
  color: var(--yellow);
}

.oc-btn-and-modal-box .switch-and-position-box {
  position: absolute;
  top: 0;
  right: 0;
  left: auto;
  padding-bottom: 2rem;
  z-index: 1000;
}

.oc-btn-and-modal-box .switch-and-position-box h3,
.oc-btn-and-modal-box .switch-and-position-box p {
  margin: 0;
}

.oc-btn-and-modal-box .switch-and-position-box h3 {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.oc-btn-and-modal-box .switch-and-position-box p {
  font-size: 1rem;
  line-height: 1.625rem;
  margin-bottom: 0.5rem;
}

.oc-btn-and-modal-box .switch-and-position-box.small .modal-header h3 {
  font-size: 1.1rem;
  line-height: 1.6rem;
}

.oc-btn-and-modal-box .switch-and-position-box.top-right {
  right: -1rem;
  top: -1rem;
  bottom: auto;
}

.oc-btn-and-modal-box .switch-and-position-box.center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.oc-btn-and-modal-box .switch-and-position-box.left {
  left: 0;
  right: auto;
}

.oc-btn-and-modal-box .switch-and-position-box.bottom-left {
  top: auto;
  bottom: -2rem;
  left: 0;
  right: auto;
}

.oc-btn-and-modal-box .switch-and-position-box.info .modal-header h3 {
  color: var(--blue);
}

.modal-box {
  background-color: rgba(255, 255, 255, 1);
  box-shadow: var(--shadow-modal);
  border-radius: 0.5rem;
  width: 100%;
}

.modal-box .modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 1rem 1rem 1.5rem;
  border-bottom: 1px solid var(--black-l4);
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}

.modal-box .modal-header h3 {
  font-size: 1.5rem;
  line-height: 2rem;
  margin: 0;
}

.modal-box .modal-header .button-modal {
  display: inline-flex;
  border: 0 solid rgba(240, 240, 245, 1);
  justify-content: center;
  cursor: pointer;
  align-items: center;
  background-color: rgba(240, 240, 245, 0);
  width: 2rem;
  height: 2rem;
  fill: var(--black-l2);
  border-radius: 0.5rem;
}

.modal-box .modal-header .button-modal:hover {
  background-color: var(--black-l4);
  fill: var(--black);
}

.modal-box .modal-header .button-modal svg {
  width: 1.4rem;
  height: 1.4rem;
  fill: var(--black-l1);
}

.modal-box .modal-content {
  padding: 1rem 1.5rem;
  color: var(--black);
  font-size: 1rem;
  line-height: 1.25rem;
  text-align: left;
}

.modal-box .modal-content p {
  margin: 0 0 1rem;
}

.modal-box .modal-content ul {
  margin: 0 0 0.5rem;
}

.modal-box .modal-footer {
  border-top: 1px solid rgba(240, 240, 245, 1);
  gap: 1rem;
  padding: 1.25rem;
  display: flex;
}
