.predictive-search-result {
  position: absolute;
  top: calc(100% + 0.5rem);
  z-index: 100;
  padding-bottom: 75px;
}

.predictive-search-result .predictive-inner {
  display: block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border: 1px solid var(--black-l4);
  border-radius: 0.5rem;
  background-color: #fff;
  box-shadow: var(--shadow-popup);
}

.predictive-search-result .predictive-inner .predictive-search-item {
  display: block;
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: 0.875rem;
  line-height: 1.8rem;
  transition: all 0.25s;
  cursor: pointer;
}

.predictive-search-result .predictive-inner .predictive-search-item:hover {
  background-color: var(--black-l4);
}
