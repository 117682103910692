.table-data-pagination-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.flowbite-pagination-with-icons ul {
  display: flex;
  align-items: center;
  height: 2rem;
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.flowbite-pagination-with-icons ul li:first-child a {
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}
.flowbite-pagination-with-icons ul li:last-child a {
  border-right-width: 1px;
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}
.flowbite-pagination-with-icons ul a {
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  background-color: rgba(255, 255, 255, 1);
  border: 1px solid rgba(60, 188, 195, 1);
  text-decoration: none;
  border-color: var(--black-l3);
  border-right-width: 0;
  color: var(--black);
  font-size: 1rem;
  line-height: 1.25rem;
}
.flowbite-pagination-with-icons ul a.active {
  background-color: var(--blue-l4);
  color: var(--blue);
}
.flowbite-pagination-with-icons ul a:not(.active):hover {
  background-color: var(--black-l4);
}
.flowbite-pagination-with-icons ul a svg {
  width: 1rem;
  height: 1rem;
}
.flowbite-pagination-with-icons.large ul {
  height: 2.5rem;
}
.flowbite-pagination-with-icons.large ul li a {
  height: 2.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: 1.25rem;
  line-height: 2.5rem;
}
.flowbite-pagination-with-icons.large ul li a svg {
  height: 1.25rem;
  width: 1.25rem;
}
