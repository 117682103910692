@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

#root,
body,
html {
  height: 100%;
  min-height: 100%;
  font-family: 'Open Sans', sans-serif;
  font-size: 18px;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}