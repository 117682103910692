.alert-msg {
  position: relative;
  box-sizing: border-box;
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: 0.25rem;
  border: 1px solid var(--blue-50);
  background-color: var(--blue-10);
  color: var(--info-color);
}
.alert-msg h1,
.alert-msg h2,
.alert-msg h3,
.alert-msg h4 {
  font-weight: var(--fw-medium);
  line-height: 1.2rem;
  margin-top: 0;
  margin-bottom: 0.5rem;
}
.alert-msg * {
  box-sizing: border-box;
}
.alert-msg hr {
  background-color: var(--blue-50);
  border: none;
  height: 1px;
}

.alert-msg-color {
  border-color: var(--yellow-50);
  color: var(--alert-color);
  background-color: var(--yellow-10);
}

.alert-msg.alert-msg-color hr {
  background-color: var(--yellow-50);
}
